import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
       <div className="footer__bottom">
        <div className="footer__bottom__copyright text text--small text--regular">
          ©2023 tobym.dev. All rights reserved.
        </div>
      </div>
      </footer>
    );
  }
}

export default Footer;
