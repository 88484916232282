import React from "react";
import Learning from "./images/server2.svg";
import Engineer from "./images/security3.svg";
import Gear from "./images/grow.svg";

class Media_container extends React.Component {
  render() {
    return (
      <div className="media-container">
        <div className="media">
          <div className="icon-container">
            <div className="media-icon">
              <img src={Learning} alt="Self Taught" />
            </div>
          </div>
          <div>
            <h2>Server Wizard</h2>
            <div className="media-text-small">
            <p className="text text--muted text-media">
               Deployed local physical machines, virtual machines, serverless functions, Kubernetes clusters, linux distros, and windows. On the cloud and off.
            </p>
            </div>
          </div>
        </div>
        <div className="media">
          <div className="icon-container">
            <div className="media-icon">
              <img src={Gear} alt="Gear"/>
            </div>
          </div>
          <div>
            <h2>Startup Tested</h2>
            <div className="media-text-small">
            <p className="text text--muted text-media">
            Was one of the first engineers at a Health Tech startup and built out the entire cloud infrastructure with Terraform.
            </p>
            </div>
          </div>
        </div>
        <div className="media">
          <div className="icon-container">
            <div className="media-icon">
              <img src={Engineer} alt="engineer"/>
            </div>
          </div>
          <div>
            <h2>Security Focused</h2>
            <div className="media-text-small">
            <p className="text text--muted text-media">
              Working in NIST, HIPAA, and SOC2 environments has hardened my code and approaches.
            </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Media_container;
